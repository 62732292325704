import styled from "styled-components";
import "react-perfect-scrollbar/dist/css/styles.css";

const StyledApp = styled.div`
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  
  a {
    &:hover {
      text-decoration: none;
    }
  }
  
  .ReactVirtualized__Table__headerColumn {
      padding: 10px !important;
  }
  
  .ReactVirtualized__Table {
    .ReactVirtualized__Table__rowColumn,
    .ReactVirtualized__Table__headerColumn {
    
      padding: 10px;
      margin-right: 0;
      
      &:not(:last-child) {
        border-right: 1px solid #edeef0;
      }
      &:first-of-type {
        margin-left: 0;
      }
  }

  .ReactVirtualized__Table__headerRow {
    background-color: #344563;
    color: #fff;
    border-radius: 4px 4px 0 0;
    height: 40px;

    .ReactVirtualized__Table__headerColumn {
      position: relative;
      padding: 3px;
      &:not(:last-child) {
        &:after {
          display: block;
          width: 1px;
          height: 30px;
          background-color: #465972;
          content: " ";
          right: 0;
          top: -3px;
          position: absolute;
        }
      }

    }
  }
  .ReactVirtualized__Table__Grid {
    &:focus {
      outline: 0;
    }
    .ReactVirtualized__Table__row {
      cursor: pointer;
      border-left: 1px solid #e5e4ea;
      border-right: 1px solid #e5e4ea;
      border-bottom: 1px solid #e5e4ea;

      &:nth-child(odd) {
        background-color: #fff;
      }
      &:nth-child(even) {
        background-color: #f6f7f9;
      }
      &:hover {
        cursor: pointer;
        background-color: #2d3d59;
        border-left-color: #2d3d59;
        border-right-color: #2d3d59;
        border-bottom-color: #2d3d59;
        color: #fff;

      }
    }
  }
}

.mt-1 {margin-top: 0.25rem;}
.mt-2 {margin-top: 0.5rem;}
.mt-3 {margin-top: 0.75rem;}
.mt-4 {margin-top: 1rem;}
.mt-5 {margin-top: 1.25rem;}
.mt-6 {margin-top: 1.5rem;}
.mt-7 {margin-top: 1.75rem;}
.mt-8 {margin-top: 2rem;}

.mr-1 {margin-right: 0.25rem;}
.mr-2 {margin-right: 0.5rem;}
.mr-3 {margin-right: 0.75rem;}
.mr-4 {margin-right: 1rem;}
.mr-5 {margin-right: 1.25rem;}
.mr-6 {margin-right: 1.5rem;}
.mr-7 {margin-right: 1.75rem;}
.mr-8 {margin-right: 2rem;}

.mb-1 {margin-bottom: 0.25rem;}
.mb-2 {margin-bottom: 0.5rem;}
.mb-3 {margin-bottom: 0.75rem;}
.mb-4 {margin-bottom: 1rem;}
.mb-5 {margin-bottom: 1.25rem;}
.mb-6 {margin-bottom: 1.5rem;}
.mb-7 {margin-bottom: 1.75rem;}
.mb-8 {margin-bottom: 2rem;}

.ml-1 {margin-left: 0.25rem;}
.ml-2 {margin-left: 0.5rem;}
.ml-3 {margin-left: 0.75rem;}
.ml-4 {margin-left: 1rem;}
.ml-5 {margin-left: 1.25rem;}
.ml-6 {margin-left: 1.5rem;}
.ml-7 {margin-left: 1.75rem;}
.ml-8 {margin-left: 2rem;}

.pt-1 {padding-top: 0.25rem;}
.pt-2 {padding-top: 0.5rem;}
.pt-3 {padding-top: 0.75rem;}
.pt-4 {padding-top: 1rem;}
.pt-5 {padding-top: 1.25rem;}
.pt-6 {padding-top: 1.5rem;}
.pt-7 {padding-top: 1.75rem;}
.pt-8 {padding-top: 2rem;}

.pr-1 {padding-right: 0.25rem;}
.pr-2 {padding-right: 0.5rem;}
.pr-3 {padding-right: 0.75rem;}
.pr-4 {padding-right: 1rem;}
.pr-5 {padding-right: 1.25rem;}
.pr-6 {padding-right: 1.5rem;}
.pr-7 {padding-right: 1.75rem;}
.pr-8 {padding-right: 2rem;}

.pb-1 {padding-bottom: 0.25rem;}
.pb-2 {padding-bottom: 0.5rem;}
.pb-3 {padding-bottom: 0.75rem;}
.pb-4 {padding-bottom: 1rem;}
.pb-5 {padding-bottom: 1.25rem;}
.pb-6 {padding-bottom: 1.5rem;}
.pb-7 {padding-bottom: 1.75rem;}
.pb-8 {padding-bottom: 2rem;}

.pl-1 {padding-left: 0.25rem;}
.pl-2 {padding-left: 0.5rem;}
.pl-3 {padding-left: 0.75rem;}
.pl-4 {padding-left: 1rem;}
.pl-5 {padding-left: 1.25rem;}
.pl-6 {padding-left: 1.5rem;}
.pl-7 {padding-left: 1.75rem;}
.pl-8 {padding-left: 2rem;}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  
  @media (max-width: 991.98px) {
    grid-template-columns: 1fr;

  }
}
`;

export default StyledApp;