import { TOAST } from './constants';

export function toaster(state = {}, action) {
  if (action.type === TOAST) {
    return {
      payload: action.payload
    };
  } else {
    return state
  }
}
